import { FaChevronCircleDown } from 'react-icons/fa'

const Component = (props) => {
  if (props.data && props.data.length > props.pageSize) {
    return <div className='bg-chLight py-2 rounded-md hover:shadow flex justify-center items-center cursor-pointer' onClick={props.onClick}>
      <FaChevronCircleDown className='text-primary mr-2' />
      {props.text}
    </div>
  }
  return <></>
}

export default Component