import useSWR from 'swr'
import { jsonGetFetcher } from '../src/fetchers'
import localStorage from '../src/local-storage'

function useChallenges (token) {
  let url = `${process.env.API}/challenges`
  
  const serverSide = Boolean(typeof window === 'undefined')
  const defaultHook = {
    loading: true,
    activeChallenges: [],
    completedChallenges: [],
    activeOrgChallenges: [],
    error: false
  }

  let initialChallenges
  if (!serverSide) initialChallenges = localStorage.getChallengeCache()

  const { data, error } = useSWR(token && !serverSide ? [url, token] : null, jsonGetFetcher, { 
    dedupingInterval: 60000,
    focusThrottleInterval: 60000,
    revalidateOnMount: true,
    fallbackData: initialChallenges
  })
  
  if (!data && !error) return { ...defaultHook, loading: true }

  if (error) {
    if (error.status === 401) {
      // user hook will handle logout, just return empty data
      return { ...defaultHook, loading: false }
    }
    if (initialChallenges && initialChallenges.length) {
      // return session groups
      return { ...defaultHook, error: true, loading: false, challenges: initialChallenges }
    }
    console.error(error)
    return { ...defaultHook, error: true, loading: false }
  }
    
  localStorage.setChallengeCache(data)
  return {
    ...defaultHook,
    loading: false,
    activeChallenges: data.activeChallenges,
    completedChallenges: data.completedChallenges,
    activeOrgChallenges: data.activeOrgChallenges || []
  }
}

export default useChallenges
