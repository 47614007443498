import React, { useState } from 'react'
import Image from 'next/image'
import { Card, CardBody } from '@nextui-org/react'
import { FaUserSecret } from 'react-icons/fa'
import Router from 'next/router'

const GroupBox = ({ group }) => {
  const [error, setError] = useState(false)
  
  return <Card shadow="sm" isPressable className='bg-chCard-background text-chCard-text' onPress={() => {
    Router.push(`/group/[id]`, `/group/${group.id}`)
  }}>
    <CardBody className="relative overflow-visible p-0 flex items-center justify-center h-[195px] md:h-[225px]">
      {group.private && <div className='absolute p-2 shadow-md right-4 top-4 bg-gray-200 border-primary border-1 rounded-lg' style={{ fontSize: '20px', color: '#9e4b04' }}>
        <FaUserSecret />
      </div>}
      {(group.logo && !error) && <Image 
        width={300} height={0}
        alt={group.name}
        src={`${process.env.CDN}/${group.logo}`}
        sizes="(min-width: 768px) 300px, 300px"
        className='object-fill h-full'
        onError={() => setError(true)} />}
      {(!group.logo || error) && <div className='text-lg md:text-2xl font-retro p-4 text-center'>{group.name}</div>}
    </CardBody>
  </Card>
}

export default GroupBox
