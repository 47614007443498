import React, { useState, useEffect, useContext } from 'react'
import { MdNotificationsActive } from 'react-icons/md'
import Image from 'next/image'
import { Button, Card, CardBody } from '@nextui-org/react'
import { useRouter } from 'next/router'
import Router from 'next/router'

import Layout from '../components/layout'
import ContentArea from '../components/content-area'
import Header from '../components/header'
import Footer from '../components/footer'
import ChallengeBox from '../components/challenge-box'
import GroupBox from '../components/group-box'
import Loading from '../components/loading'
import ErrorCard from '../components/error-card'
import Divider from '../components/divider'
import ViewMore from '../components/view-more'

import { UserContext } from '../contexts/user-context'
import { challengeSorter } from '../src/sorters'
import useGroups from '../hooks/use-groups'
import useChallenges from '../hooks/use-challenges'
import useHasMounted from '../hooks/use-has-mounted'

const HoundquartersComponent = <Card className='bg-chCard-background mt-4 mb-2 flex justify-center w-full' isPressable onPress={() => {
    Router.push(`/houndquarters`, `/houdquarters`)
  }}>
  <CardBody>
    <div className='flex items-center'>
      <div className='px-0'>
        <div className='text-chSubtext text-sm text-center'>Looking for more challenges?</div>
        <div className='text-primary text-2xl text-center'>Public Challenges</div>
      </div>
      <div className='pl-2'>
        <Image src='/hound.png' width={75} height={75} unoptimized alt='join a public challenge' />    
      </div>
    </div>
  </CardBody>
</Card>

const CreateAChallengeComponent = <Card className='bg-chCard-background mt-4 mb-2 flex justify-center' isPressable onPress={() => {
    Router.push(`/houndroid`, `/houndroid`)
  }}>
  <CardBody>
    <Image src='/create-a-challenge.png' width={300} height={70} alt='create a challenge' style={{ width: '300px', height: '70px' }} />
  </CardBody>
</Card>

const OrgChallengesComponent = ({ user, activeOrgChallenges, activeChallenges }) => {
  const activeOrgChallengesSize = 15
  const [activeOrgChallengesLength, setActiveOrgChallengesLength] = useState(activeOrgChallengesSize)
  
  const activeIds = activeChallenges.map(item => item.id)
  
  // if user has joined all org challenges, do NOT display
  if (!activeOrgChallenges.some(item => !activeIds.includes(item.id))) return <></>
  
  return <div className={`${activeChallenges.length && 'mt-20'}`}>
    <Divider>Active {user?.organization?.name} Challenges</Divider>
    <div className='text-center text-chSubtext mb-8 px-6 md:px-20'>
      All the challenges that have been created in the {user?.organization?.name} organization. This view is only available to administrators of the {user?.organization?.name} organization.
    </div>
    <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-12'>
      {activeOrgChallenges
        .sort(challengeSorter)
        .slice(0, activeOrgChallengesLength)
        .map((challenge, index) => {
          const g = {
            id: challenge.groupId,
            name: challenge.groupName,
            key: challenge.groupKey,
            type: challenge.groupType
          }
          return (<ChallengeBox key={index} group={g} challenge={challenge} />)
        })
      }
    </div>
    <ViewMore onClick={() => {
      const newSize = activeOrgChallengesLength + activeOrgChallengesSize
      setActiveOrgChallengesLength(newSize)
    }} pageSize={activeOrgChallengesLength} data={activeOrgChallenges} text='View More Active Challenges' />

    {!Boolean(activeChallenges.length) && <div className='grid grid-cols-1 md:grid-cols-2 gap-4 w-full mt-10'>
      <div className='w-auto mx-auto'>{CreateAChallengeComponent}</div>
      <div className='w-auto mx-auto'>{HoundquartersComponent}</div>
    </div>}
  </div>
}

const Challenges = () => {
  const hasMounted = useHasMounted()

  const userHook = useContext(UserContext)
  const user = userHook.user
  if (!userHook.error && !userHook.loggedIn && !userHook.serverSide) userHook.requireSignin()
  
  // load challenges
  const challengeData = useChallenges(user.token)
  const { activeChallenges, completedChallenges, activeOrgChallenges } = challengeData
  const invitedChallenges = activeChallenges.filter(challenge => !challenge.joined)
  const joinedChallenges = activeChallenges.filter(challenge => challenge.joined)
  const showInstructions = !challengeData.loading && !activeChallenges.length
  const [pushStatus, setPushStatus] = useState('')
  const [isNativeApp, setisNativeApp] = useState(false)
  
  const groupData = useGroups(user) 

  const router = useRouter()

  useEffect(() => {
    if (window.isNativeApp) {
      setisNativeApp(true)
      window.setPushStatus = (val) => {
        setPushStatus(val || 'undetermined')
      }
    }
  }, [])
  
  if (Boolean(typeof window !== 'undefined') && !user.id) return
  return (
    <Layout
      title='Challenge Hound: Challenges'
      description='Challenge Hound Challenge Dashboard'
      keywords='challenge hound'>
      <Header section='challenges' main />
      <main>
        <ContentArea>
          {hasMounted && <>
            {((challengeData.loading || challengeData.error) && !Boolean(activeChallenges.length)) && 
              <div style={{ height: '350px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {challengeData.error && <ErrorCard color='secondary' className='mb-2'>Attempting to reconnect to Challenge Hound.</ErrorCard>}
                <Loading />
              </div>
            }
            
            {(!challengeData.loading && isNativeApp && Boolean(activeChallenges.length || activeOrgChallenges.length) && pushStatus === 'undetermined') && <div className='flex justify-center mb-10'>
              <Button 
                color='primary'
                onPress={() => { window.ReactNativeWebView?.postMessage(JSON.stringify({ requestPush: true })) }}
                startContent={<MdNotificationsActive style={{ fontSize: '20px' }}/>} >
                Enable Push Notifications
              </Button>
            </div>}
            
            {userHook.isAnonymous(user) && <div className={`flex justify-center ${isNativeApp ? '' : 'md:mt-10' }`}>
                <Button color='danger' onPress={() => { router.push('/account', `/account`) }} size='sm'>Click to Update Your Profile</Button>
            </div>}

            
            {(!challengeData.loading && !activeOrgChallenges.length && !challengeData.error && showInstructions) && <>
              <Divider>Get Started!</Divider>
              
              <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-16 border-b-1 pb-16'>
                <div className='flex flex-col justify-center items-center cursor-pointer' onClick={() => {
                  router.push('/houndroid', `/houndroid`)
                }} >
                  <Image src='/ch-onboarding-1.png' width={190} height={190} alt='Step One - Create a Challenge' className='w-[30%] md:w-auto' />
                  <div className='text-2xl text-info hover:text-info-800 mt-2 cursor-pointer'>1. Create Challenge</div>
                  <div className='text-md text-gray-400 text-center'>Craft the perfect challenge using Houdroid AI or configure manually.</div>
                </div>
                <div className='flex flex-col justify-center items-center'>
                  <Image src='/ch-onboarding-2.png' width={190} height={190} alt='Step Two - Invite Participants' className='w-[30%] md:w-auto' />
                  <div className='text-2xl mt-2'>2. Invite Participants</div>
                  <div className='text-md text-gray-400 text-center'>Share the unique challenge link<br/>via text, email, or socially.</div>
                </div>
                <div className='flex flex-col justify-center items-center cursor-pointer' onClick={() => {
                  router.push('/activity/new/edit', `/activity/new/edit`)
                }} >
                  <Image src='/ch-onboarding-3.png' width={190} height={190} alt='Step Three - Log Activities'  className='w-[30%] md:w-auto' />
                  <div className='text-2xl text-info hover:text-info-800 m1-2'>3. Log Activities</div>
                  <div className='text-md text-gray-400 text-center'>Dynamic challenge statuses, leaderboards, and reports.</div>
                </div>
              </div>
              
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-12 md:mb-0'>
                <div>
                  <h2 className='text-xl md:text-2xl mb-4'>Looking to Join a Specific Challenge?</h2>
                  <div style={{ float: 'left', marginRight: '10px' }}><Image src='/email.gif' width={75} height={34} alt='email' unoptimized /></div>
                  You&apos;ll need the <span className='font-bold'>private link</span> from the challenge administrator, or the person that invited you.
                </div>
                <div className='flex items-center'>
                  <div className='w-auto mx-auto'>{HoundquartersComponent}</div>
                </div>
              </div>
            </>}
            
            {Boolean(invitedChallenges.length) && <div className='mb-12'>
              <Divider>Challenge Invites</Divider>
              <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                {invitedChallenges
                  .sort(challengeSorter)
                  .sort((a, b) => {
                    return (a.groupKey === 'public') - (b.groupKey === 'public')
                  })
                  .map((challenge, index) => {
                    const g = {
                      id: challenge.groupId,
                      name: challenge.groupName,
                      key: challenge.groupKey,
                      type: challenge.groupType
                    }
                    return (<ChallengeBox user={user} key={index} group={g} challenge={challenge} toJoin />)
                  })
                }
                {(!joinedChallenges.length && invitedChallenges.length % 3 !== 0) && <div className='w-auto mx-auto'>
                  {CreateAChallengeComponent}
                  <div className='height-4'>&nbsp;</div>
                  {HoundquartersComponent}
                </div>}
              </div>
              {!joinedChallenges.length || invitedChallenges.length % 3 === 0 && <div className='grid grid-cols-1 md:grid-cols-2 gap-4 w-full mt-10'>
                <div className='w-auto mx-auto'>{CreateAChallengeComponent}</div>
                <div className='w-auto mx-auto'>{HoundquartersComponent}</div>
              </div>}
            </div>}

            {Boolean(joinedChallenges.length) && <div className='mb-12'>
              <Divider>Challenges Joined</Divider>
              <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                {joinedChallenges
                  .sort(challengeSorter)
                  .map((challenge, index) => {
                    const g = {
                      id: challenge.groupId,
                      name: challenge.groupName,
                      key: challenge.groupKey,
                      type: challenge.groupType
                    }
                    return (<ChallengeBox user={user} key={index} group={g} challenge={challenge} />)
                  })
                }
                {(activeChallenges.length && activeChallenges.length % 3 !== 0) && <div className='w-auto mx-auto'>
                  {CreateAChallengeComponent}
                  <div className='height-4'>&nbsp;</div>
                  {HoundquartersComponent}
                </div>}
              </div>
              {!activeChallenges.length || activeChallenges.length % 3 === 0 && <div className='grid grid-cols-1 md:grid-cols-2 gap-4 w-full mt-10'>
                <div className='w-auto mx-auto'>{CreateAChallengeComponent}</div>
                <div className='w-auto mx-auto'>{HoundquartersComponent}</div>
              </div>}
            </div>}

            {user && user.organization && Boolean(activeOrgChallenges.length) && 
              <OrgChallengesComponent user={user} activeOrgChallenges={activeOrgChallenges} activeChallenges={activeChallenges} />}

            
            {(groupData.loading || Boolean(groupData.groups.length)) && <>
              <div className='mt-20'>
                <Divider>Groups</Divider>
              </div>
            
              {((groupData.loading || groupData.error) && !groupData.groups.length) && 
                <div style={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  {challengeData.error && <ErrorCard color='secondary' className='mb-2'>Attempting to reconnect to Challenge Hound.</ErrorCard>}
                  <div style={{ width: '100%' }}><Loading /></div>
                </div>
              }
              {(groupData && !groupData.loading && !groupData.error && !Boolean(groupData.groups.length)) && <div className='text-center text-gray-400 mb-12'>No groups found.</div> }
            
              {(!groupData.loading && Boolean(groupData.groups.length)) && <div className='grid grid-cols-2 md:grid-cols-4 gap-4 mb-20'>
                {groupData.groups.sort((a, b) => {
                    const aDate = new Date(a.createdAt)
                    const bDate = new Date(b.createdAt)
                    if (aDate.getTime() < bDate.getTime()) {
                      return 1
                    } else if (aDate.getTime() > bDate.getTime()) {
                      return -1 
                    } else {
                      return 0
                    }
                  })
                  .map((group, index) => <GroupBox key={group.id} group={group} />)
                }
              </div>}
            </>}

            {(challengeData.loading || Boolean(completedChallenges.length)) && <>
            
              <Divider>Completed Challenges</Divider>
            
              {((challengeData.loading || challengeData.error) && !completedChallenges.length) && 
                <div style={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  {challengeData.error && <ErrorCard color='secondary mb-2'>Attempting to reconnect to Challenge Hound.</ErrorCard>}
                  <Loading />
                </div>
              }

              {(!challengeData.loading && !challengeData.error && !Boolean(completedChallenges.length)) &&
                <div className='pb-8'>
                  <div className='text-xl text-center'>No Trophies Yet!</div>
                  <div className='text-lg text-center text-gray-400'>Complete a challenge to start adding to your trophy case.</div>
                </div>
              }
            
              {(!challengeData.loading && Boolean(completedChallenges.length)) &&
                <div className='grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4'>
                  {completedChallenges
                    .sort(challengeSorter)
                    .map((challenge, index) => {
                      const g = {
                        id: challenge.groupId,
                        logo: challenge.groupLogo,
                        name: challenge.groupName,
                        key: challenge.groupKey,
                        type: challenge.groupType
                      }
                      return <ChallengeBox key={challenge.id} group={g} challenge={challenge} />
                    })
                  }
                </div>
              }
            </>}
            
          </>}
        </ContentArea>
      </main>
      <Footer />
    </Layout>
  );
}

export default Challenges
