import useSWR from 'swr'
import { jsonGetFetcher } from '../src/fetchers'

function useGroups (user, dedupingInterval = 2000) {
  const url = `${process.env.API}/groups`

  const serverSide = Boolean(typeof window === 'undefined')
  const defaultHook = {
    loading: true,
    groups: [],
    error: false
  }
  
  const initialGroups = user && user.groupsJoined ? user.groupsJoined : []

  const { data, error } = useSWR(user && user.token && !serverSide ? [url, user.token] : null, jsonGetFetcher, {
    revalidateOnMount: true,
    fallbackData: initialGroups,
    dedupingInterval
  })
  
  if (!data && !error) return { ...defaultHook, loading: true }
  if (error) {
    if (error.status === 401) {
      // user hook will handle logout, just return empty data
      return { ...defaultHook, loading: false }
    }
    if (initialGroups.length) {
      // return session groups
      return { ...defaultHook, error: true, loading: false, groups: initialGroups }
    }
    console.error(error)
    return { ...defaultHook, error: true, loading: false }
  }

  return { ...defaultHook, loading: false, groups: data }
}

export default useGroups
