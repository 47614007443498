import React, { useState, useContext } from 'react'
import Router from 'next/router'
import Image from 'next/image'
import { FaTrophy } from 'react-icons/fa'
import TimeAgo from 'react-timeago'
import { differenceInDays } from 'date-fns'

import { Card } from '@nextui-org/react'

import { ChallengeObjective } from './challenge-details'
import { UserContext } from '../contexts/user-context'
import { formatNumber, formatMetric, displayMeasurement, formatRaceTime, formatDuration, formatNumericDate, capitalizeFirstLetter } from '../src/formatter'

const ChallengeImage = ({ image, name, imageChild }) => {
  const [error, setError] = useState(false)
  const randomNum = Math.floor(Math.random() * 2) + 1;
  return <div className='w-full h-[175px] overflow-hidden relative'>
    <Image
      width={0} height={0}
      alt={name}
      fill={true}
      src={(image && !error) ? `${process.env.CDN}/${image}` : `/challenge-placeholders/placeholder-${randomNum}.jpg`}
      sizes="(min-width: 768px) 400px, 300px"
      className='rounded-md mx-auto w-auto object-cover object-center'
      onError={() => setError(true)}
    />
    {imageChild}
  </div>
}

const getChallengeStatus = (user, challenge) => {
  if (challenge.measure === 'pointGoal') {
    const display = challenge.sumPoints || challenge.pointGoal
    return challenge.isRank ? 'Points' : `${Math.round(display * 100) / 100} points`
  } else if (challenge.measure === 'distance') {
    const display = challenge.sumDistance || challenge.distance
    return challenge.isRank ? 'Distance' : `${formatMetric(display, 2, 'lg', user.imperial)} ${displayMeasurement(user.imperial, 'lg', true, false)}`
  } else if (challenge.measure === 'elevation') {
    const display = challenge.sumElevation || challenge.elevation
    return challenge.isRank ? 'Elevation' : `${formatMetric(display, 2, 'sm', user.imperial)} ${displayMeasurement(user.imperial, 'sm', true, false)}`
  } else if (challenge.measure === 'durationGoal') {
    const display = challenge.sumDuration || challenge.durationGoal
    return challenge.isRank ? 'Duration' : `${formatDuration(display)}`
  } else if (challenge.measure === 'activityGoal') {
    const display = challenge.sumActivities || challenge.activityGoal
    return challenge.isRank ? 'Activities' : `${formatNumber(display)} activities`
  } else if (challenge.measure === 'race') {
    const display = challenge.sumDuration || challenge.raceTime
    return formatRaceTime(display)
  } else if (challenge.measure === 'streakGoal') {  
    const display = challenge.longestStreak || challenge.streakGoal
    return `${formatNumber(display)} day streak`
  }
  return ''
}

const ChallengeBoxActive = ({ group, challenge, toJoin }) => {
  const userHook = useContext(UserContext)
  const user = userHook.user

  const link = toJoin ? 'join' : 'challenge'
  const status = getChallengeStatus(user, challenge)
  
  let left = '' 
  let right = ''
  if (challenge.completed) {
    left = status
    right = <div className='flex justify-end items-center gap-1'>
      <FaTrophy className='text-chOffWhite text-lg' /> <TimeAgo date={challenge.completed} />
    </div>
  } else {
    if (challenge.scheduled) {
      const days = differenceInDays(new Date(challenge.start), new Date())
      right = `Starts in ${days} days`
      left = '' 
    } else if (challenge.over) {
      right = `Ended ${formatNumericDate(challenge.end)}`
      left = status
    } else {
      right = `${challenge.daysLeft} days left`
      left = status
    }
  }
  
  const imageChild = <>
    <div className='absolute bottom-0 pb-2 w-full z-20 grid grid-cols-2 bg-gradient-to-t from-chDarkBlue via-40% h-10'>&nbsp;</div>
    <div className='absolute bottom-0 pb-2 w-full z-30 grid grid-cols-2 text-chOffWhite text-sm'>
      <div className='pl-4 text-left font-bold'>{left}</div>
      <div className='pr-4 text-right font-bold'>{right}</div>
    </div>
  </>

  return <Card radius='lg' shadow='sm' isPressable onPress={() => {
    Router.push(`/${link}/[id]`, `/${link}/${challenge.id}`)
  }} className={`bg-chCard-background text-chCard-text border-none`}>
      <ChallengeImage name={challenge.name} image={challenge.logo} imageChild={imageChild} />
      <div className='w-full px-3 mb-8'>
        <div className={`text-chSubtext text-sm mb-1 truncate text-center mt-2`}>{group.name}</div>
        <div className={`text-chHeadline font-retro text-center text-2xl leading-7 mb-2`}>{challenge.challengeName}</div>
        <ChallengeObjective challenge={challenge} size={'sm'} />
      </div>
  </Card>
}

export default ChallengeBoxActive
