export const challengeSorter = (a, b) => {
  
  if (a.completedTs !== b.completedTs) {
    return b.completedTs - a.completedTs
  }

  if (a.scheduled !== b.scheduled) {
    return a.scheduled - b.scheduled
  }

  if (a.daysLeft !== b.daysLeft) {
    return a.daysLeft - b.daysLeft
  }

  return a.challengeName.localeCompare(b.challengeName);
}
